<template>
  <div class="boardDiv">
    <div class="winbox boardDivLeft">
      <!-- 左边 -->
      <div class="boardDivLeftMedHead">
        <h3>{{ $t("Product information index") }}</h3>
        <span>{{ $t("DISCOVER MORE index") }}</span>
      </div>
      <div style="margin-top:11px;">
        <el-carousel :interval="0">
          <el-carousel-item v-for="(item,index) in videoList" :key="index">
            <video class="boardVideo" width="100%" alt="" :src="item" controls @ended="replayVideo(index)" @play="onPlay(index)" @pause="onPause(index)">
            </video>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 右边 -->
    <div class="winbox boardDivRight">
      <div class="winbox_header">
        <h3>{{ $t("Bulletin Board index") }}</h3>
        <span @click="JumpToAllNotice">{{ $t("MORE") }} &gt;</span>
      </div>
      <div class="winbox_content">
        <p v-for="(item,index) in noticeData" :key="index" class="noticeItem">
          <span class="winbox_content_title" @click="JumpToNoticeDetail(item)">
            <strong>
              <img v-if="index === 0" class="newLogo" src="~@/assets/images/board/new.png" />
              <a v-if="lang=='zh-CN'">{{ item.shot_title }}</a>
              <a v-else>{{ item.shot_title_en }}</a>
            </strong>
          </span>
          <span class="winbox_content_time">
            {{ new Date(item.createTime).toLocaleString("zh-Hans-CN",{year:'numeric',month:'2-digit',day:'2-digit'}).replace(/\//g,'-') }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import notice from "@/api/web/home/notice";

export default {
  data() {
    return {
      message: "Hello, Vue!",
      noticeData: [],
      lang: "zh-CN",
      videoList: [
        "https://ximiphoto.oss-cn-hangzhou.aliyuncs.com/video/sp1.mp4",
        "https://ximiphoto.oss-cn-hangzhou.aliyuncs.com/video/sp2.mp4",
        "https://ximiphoto.oss-cn-hangzhou.aliyuncs.com/video/sp3.mp4",
        "https://ximiphoto.oss-cn-hangzhou.aliyuncs.com/video/sp4.mp4",
      ],
    };
  },
  created() {
    this.getData();
    this.getLang();
  },
  methods: {
    // 视频重播
    replayVideo(index) {
      const videoElement = this.$el.querySelectorAll(".boardVideo")[index];
      videoElement.load(); // 重新加载视频
      videoElement.play(); // 播放视频
    },
    onPlay(index) {
      // console.log("视频播放", index);
      // const videoElement = this.$el.querySelectorAll(".boardVideo")[index];
      // videoElement.play();
      this.$el.querySelectorAll(".boardVideo").forEach((video, i) => {
        if (i !== index) {
          video.pause();
        }
      });
    },
    onPause(index) {
      console.log("视频暂停", index);
    },
    getData() {
      notice
        .get({
          limit_num: 6,
        })
        .then((res) => {
          this.noticeData = res.data.data ? res.data.data : [];
        });
    },
    getLang() {
      this.lang = this.$i18n.locale;
    },
    JumpToNoticeDetail(row) {
      let { href } = this.$router.resolve({
        path: "/web/noticeDetail",
        query: {
          id: row.id,
        },
      });
      window.open(href);
    },
    //跳转到列表页
    JumpToAllNotice() {
      let { href } = this.$router.resolve({
        path: "/web/noticeList",
        query: {},
      });
      window.open(href);
    },
  },
};
</script>

<style socped>
.boardDiv {
  /* border: 1px solid red; */
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.boardDiv .winbox {
  /* border: 1px solid gold; */
  height: 385px;
}

/* 左边视频部分 */
.boardDivLeft {
  width: 592px;
  margin-right: 13px;
  background: url("~@/assets/images/board/01.jpg") no-repeat;
  padding: 21px 30px;
}
.boardDivLeftMedHead {
  display: flex;
}
.boardDivLeftMedHead h3 {
  font-size: 20px;
  font-weight: 700;
  color: #396d56;
  width: 77%;
}
.boardDivLeftMedHead span {
  font-size: 14px;
  color: #8e8e8e;
  width: 150px;
  /* border: 1px solid red; */
  height: 30px;
  line-height: 36px;
  text-align: right;
}

.boardVideo {
  cursor: pointer;
}

/*======================================== */
/*=========== 公告内容 ==================== */
/*======================================== */
/* 公告右边 */
.boardDivRight {
  width: 607px;
  /* 从上到下的渐变 */
  background: url("~@/assets/images/board/02.jpg") no-repeat;
  padding: 21px 30px;
}
.winbox_header {
  display: flex;
}
.winbox_header h3 {
  font-size: 20px;
  font-weight: 700;
  color: #a12536;
  width: 80%;
}
.winbox_header span {
  font-size: 14px;
  color: #8e8e8e;
  cursor: pointer;
  width: 95px;
  height: 30px;
  line-height: 36px;
  text-align: right;
}
/* 公告内容 */
.winbox_content {
  font-size: 14px;
  color: #8e8e8e;
}
.winbox_content p {
  border-bottom: 1px solid #f3c5cb;
  height: 48px;
  line-height: 62px;
  cursor: pointer;
}

.winbox_content .winbox_content_title:hover,
.winbox_content p:hover {
  color: #f81d64;
}
.winbox_content .winbox_content_title {
  width: 80%;
  color: #454545;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 文字超出部分显示省略号 */
  vertical-align: middle; /* 垂直对齐 */
}
.winbox_content .winbox_content_title strong {
  position: relative;
  font-weight: 100;
  font-size: 16px;
}
.newLogo {
  position: absolute; /* 绝对定位 */
  right: -28px;
  top: -21px;
  width: 50px;
}
.winbox_content .winbox_content_time {
  width: 20%;
  text-align: center;
}
</style>